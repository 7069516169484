import React from 'react'
import Layout from '../components/layout'
import Pink from '../images/teamPink.jpg'
import Solas from '../images/solasWalk.jpg'
import Hair from '../images/pinkLadies.jpg'
import Boat from '../images/boatPush.jpg'
const Participation = () => (
  <Layout>
    <h1>Waterford Dragon Warriors Participate In Many Different Events</h1>
    <h3>Pink Paddle October Breast Cancer Awareness</h3>
    <p>
       Waterford Dragon Warriors is a team of breast cancer survivors and supporters. We support breast cancer 
       awareness in any way we can. In October we wear pink in support of breast cancer awareness month.
    </p>
    <img  src={Pink} alt="pink paddle"/>
    <br></br>
    <h3>Walk/Run For Life 10KM For Solas Cancer Support Centre</h3>
    <p>
        In October 2018, Waterford Dragon Warriors entered a team to walk/run 10km for the Solas Cancer Support Centre in Waterford City.
    </p>
    <img src={Solas} alt="solas walk"/>
    <br></br>
    <h3>Ladies Go Pink To Make The Boys Wink!</h3>
    <p>
        In October 2018, ladies from Waterford Dragon Warriors and The Ivy Wellness and Beauty Salon, dyed their hair pink
        to raise money for the Solas Cancer Support Center.
    </p>
    <img src={Hair} alt="ladies with pink hair"/>
    <br></br>
    <h3>Breast Cancer Awareness Day</h3>
    <p>
        On the 20th October 2018, the Waterford Dragon Warriors pushed one of their dragon boats from the Boat Club to
        John Roberts Square in Waterford City 
        to raise awareness for Breast Cancer Awareness Day.
    </p>
    <img src={Boat} alt="the team and the boat"/>

  </Layout>
)

export default Participation
